.account-body {
  background-color: var(--gray-wild-sand);

  & .footer {
    margin-top: 0;
  }

  & .button {
    margin: 0;
  }
}

#generic-error {
  align-self: center;
  border-radius: 16px;
  box-sizing: border-box;
  max-width: 343px;
}

#data-and-devices .data-and-devices-block {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & h2 {
    margin: 0;
  }

  & p {
    margin: 0;
  }

  & .button {
    align-self: flex-end;
  }
}

#logout-accept-button-row {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 16px;

  &[hidden] {
    display: none !important;
  }
}

#delete-account:not([hidden]) main {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & h2 {
    margin: 0;
  }

  & p {
    margin: 0;
  }

  & .alert {
    margin: 0;
  }

  & .delete-account-buttons {
    display: flex;
    gap: 16px;
    justify-content: end;
  }
}

#menu.account-content:not([hidden]) {
  background: var(--gray-wild-sand);
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 343px;
  margin: 48px 16px;
  align-self: center;

  & header {
    height: 34px;
    align-self: start;

    & h1 {
      margin: 0;
    }
  }

  & main {
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin: 0;
  }
}

.account-content {
  flex-grow: 1;
  font-family: var(--font-stack-Open-Sans);
  font-size: 15px;
  line-height: 21px;
  font-style: normal;
  background: var(--white);
  color: var(--gray-slate);
  width: 100%;

  & > main {
    & h2 {
      margin: 0;
    }

    & .phonePinCard {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & .phonePin {
        font-weight: 600;
      }

      & h2 {
        margin: 0;
      }
    }

    margin: 48px auto 48px;
    max-width: 343px;

    & h2 {
      font-size: 15px;
      font-weight: 700;
      line-height: 21px;
      margin-top: 48px;
      margin-bottom: 24px;
      color: var(--gray-midnight);
    }
  }

  & a:not(.button) {
    color: var(--gray-slate);
  }

  & .account-nav {
    max-width: 343px;
  }

  & .account-card {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & .name {
      color: var(--gray-stone);
      font-family: var(--font-stack-Akko);
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 33px;
      letter-spacing: 0.16px;
      white-space: pre-wrap;
      word-break: break-word;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      /* supported in most browsers, the others have to live with long lines */
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    & .mail {
      color: var(--gray-slate);
      font-family: var(--font-stack-Open-Sans);
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .phonePin-section {
      display: flex;
      gap: 8px;
      font-family: var(--font-stack-Open-Sans);
      margin-top: 8px;

      & .phonePin-label {
        font-size: 15px;
        font-weight: 700;
        line-height: 21px;
        color: var(--gray-stone);
      }

      & .phonePin {
        font-size: 15px;
        font-weight: 600;
        line-height: 21px;
      }
    }
  }

  & .account-links {
    background: var(--white);
    border-radius: 16px;
    list-style: none;
    padding: 8px 16px;

    & .account-link {
      display: flex;
      gap: 16px;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      color: var(--gray-midnight);
      font-family: var(--font-stack-Open-Sans);
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      text-decoration: none;
      height: 60px;
      padding: 16px 8px;
      border-radius: 16px;

      &::after {
        content: url("../img/icon-chevron.svg");
        color: var(--gray-midnight);
        width: 19px;
        height: 19px;
        margin-left: auto;
      }

      & .icon {
        width: 28px;
        height: 28px;
      }

      &:hover {
        background: var(--gray-wild-sand);
      }

      &:focus-visible {
        outline: 1px solid var(--gray-stone);
      }
    }

    & .account-link-item {
      &:first-child {
        padding-bottom: 4px;
      }

      &:not(:first-child, :last-child) {
        padding: 4px 0;
      }

      &:last-child {
        padding-top: 4px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--gray-wild-sand);
      }
    }
  }

  & .account-delete {
    align-self: center;
    margin: 0;
  }

  & .register-form {
    display: block;

    & h2 {
      margin: 0;
    }

    & .required-info {
      color: var(--gray-slate);
      font-family: var(--font-stack-Open-Sans);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
    }

    & .button {
      width: fit-content;
      margin: 0;
    }
  }
}

hr {
  margin: 24px 0;
  border: 1px solid var(--gray-sand);
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.account-content--form__groups {
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .form__group--with-button {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

#email-password .content__card {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

#ad-preferences {
  & .newsletter {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & h2 {
      margin: 0;
    }

    & p {
      margin: 0;
    }

    & .links {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-self: end;
      align-items: end;
    }
  }

  & .alert {
    border-radius: 16px;
    margin-top: 48px;
  }
}

.logout-button {
  background-color: transparent;
}
